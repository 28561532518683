import { Box, Chip, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { set } from "date-fns";

export function UsersFilterChat({ onFiltered, initialUsers, currentUser, chatOwner }) {
  const [users, setUsers] = useState([]);
  const [selecteds, setSelecteds] = useState(initialUsers || []);

  useEffect(() => {

    async function fetchData() {
      await loadUsers();
    }
    fetchData();
  }, []);


  useEffect(() => {
    if (Array.isArray(initialUsers) && initialUsers.length > 0) {
      setSelecteds(initialUsers);
      console.log("Esses são os usuários selecionados: ");
      console.log("initialUsers: ", initialUsers);
      console.log("current user: ", currentUser);
      console.log("chat owner: ", chatOwner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUsers]);

  const loadUsers = async () => {
    try {
      const { data } = await api.get(`/users/list`);
      const userList = data.filter((u) => u.id !== currentUser.id).map((u) => ({ id: u.id, name: u.name }));
      setUsers(userList);
      //setUsers(data);
    } catch (err) {
      toastError(err);
    }
  };

  const toggleSelection = (user) => {
    if (user.id === chatOwner.id && currentUser.id !== chatOwner.id) {
      toastError("Você não pode remover o dono do chat.");
      return;
    }
    let newSelecteds;
    if (selecteds.some((u) => u.id === user.id)) {
      newSelecteds = selecteds.filter((u) => u.id !== user.id);
    } else {
      newSelecteds = [...selecteds, user];
    }
    setSelecteds(newSelecteds);
    onFiltered(newSelecteds);
  };

  return (
    <Box style={{ padding: "10px", minWidth: "35ch" }}>
      <Typography variant="h6">Selecione os usuários:</Typography>
      <Box style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginTop: "10px" }}>
        {users.map((user) => (
          <Chip
            variant="outlined"
            key={user.id}
            label={user.name}
            onClick={() => toggleSelection(user)}
            style={{
              backgroundColor: selecteds.some((u) => u.id === user.id)
                ? user.id === chatOwner.id && currentUser.id !== chatOwner.id
                  ? "#ff6961" // Vermelho para chatOwner se currentUser não for chatOwner
                  : "#90ee90" // Verde para outros selecionados
                : "#bfbfbf", // Cinza para não selecionados
              color: "black",
              fontWeight: selecteds.some((u) => u.id === user.id) ? "bold" : "normal",
              cursor: "pointer",
            }}
            onDelete={
              selecteds.some((u) => u.id === user.id) && !(user.id === chatOwner.id && currentUser.id !== chatOwner.id)
                ? () => toggleSelection(user)
                : undefined
            }
          />
        ))}
      </Box>

      {currentUser.id !== chatOwner.id && (
        <Box style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
          <Box style={{
            width: "12px",
            height: "12px",
            backgroundColor: "#ff6961",
            marginRight: "5px",
            border: "1px solid black"
          }} />
          <Typography variant="caption" color="textSecondary">
            Criou o chat.
          </Typography>
        </Box>
      )}

    </Box>
  );
}