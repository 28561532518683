import React, { useState, useEffect, useReducer, useCallback } from "react";
import { toast } from "react-toastify";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";


import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ScheduleModal from "../../components/ScheduleModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import moment from 'moment';
import { capitalize } from 'lodash';
import ScheduleModalEdit from "../../components/ScheduleModalEdit";

// A custom hook that builds on useLocation to parse
// the query string for you.
const getUrlParam = (param) => {
  return (new URLSearchParams(window.location.search)).get(param)
}

const reducer = (state, action) => {
  if (action.type === "LOAD_SCHEDULES") {
    const schedules = action.payload;
    const newSchedules = [];

    schedules.forEach((schedule) => {
      const scheduleIndex = state.findIndex((s) => s.id === schedule.id);
      if (scheduleIndex !== -1) {
        state[scheduleIndex] = schedule;
      } else {
        newSchedules.push(schedule);
      }
    });

    return [...state, ...newSchedules];
  }

  if (action.type === "UPDATE_SCHEDULES") {
    const schedule = action.payload;
    const scheduleIndex = state.findIndex((s) => s.id === schedule.id);

    if (scheduleIndex !== -1) {
      state[scheduleIndex] = schedule;
      return [...state];
    }
    return [...state];

  }

  if (action.type === 'CREATE_SCHEDULE') {
    const schedule = action.payload;
    return [...state, ...schedule];
  }

  if (action.type === "DELETE_SCHEDULE") {
    const scheduleId = action.payload;

    const scheduleIndex = state.findIndex((s) => s.id === scheduleId);
    if (scheduleIndex !== -1) {
      state.splice(scheduleIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    position: "relative"
  },
}));

const Schedules = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [deletingSchedule, setDeletingSchedule] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [schedules, dispatch] = useReducer(reducer, []);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [contactId, setContactId] = useState(+getUrlParam('contactId'));
  const [tabValue, setTabValue] = useState(0);
  const [pendingSchedules, setPendingSchedules] = useState([]);
  const [sentSchedules, setSentSchedules] = useState([]);
  const [editScheduleModalOpen, setEditScheduleModalOpen] = useState(false);

  const fetchSchedules = useCallback(async () => {
    try {
      const { data } = await api.get("/schedules/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_SCHEDULES", payload: data.schedules });
      setHasMore(data.hasMore);
      setContactId(null);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber]);

  const handleOpenScheduleModalFromContactId = useCallback(() => {
    if (contactId) {
      handleOpenScheduleModal();
    }
  }, [contactId]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchSchedules();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, contactId, fetchSchedules, handleOpenScheduleModalFromContactId]);

  useEffect(() => {
    handleOpenScheduleModalFromContactId();
    const socket = openSocket();

    socket.on("schedule", (data) => {
      if (data.action === "update") {
        dispatch({ type: "UPDATE_SCHEDULES", payload: data.schedule });
      }

      if (data.action === "create") {
        setSearchParam("");
        setPageNumber(1);        
        fetchSchedules();
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_SCHEDULE", payload: +data.scheduleId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const cleanContact = () => {
    setContactId(null);
  }

  const handleOpenScheduleModal = () => {
    setSelectedSchedule(null);
    setScheduleModalOpen(true);
  };

  const handleCloseScheduleModal = () => {
    setSelectedSchedule(null);
    setScheduleModalOpen(false);
  };

  const handleOpenEditScheduleModal = (schedule) => {
    setSelectedSchedule(schedule);
    setEditScheduleModalOpen(true);
  };

  const handleCloseEditScheduleModal = () => {
    setSelectedSchedule(null);
    setEditScheduleModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditSchedule = (schedule) => {
    setSelectedSchedule(schedule);
    setEditScheduleModalOpen(true);
  };

  const handleDeleteSchedule = async (scheduleId) => {
    try {
      await api.delete(`/schedules/${scheduleId}`);
      toast.success(i18n.t("schedules.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingSchedule(null);
    setSearchParam("");
    setPageNumber(1);

    dispatch({ type: "RESET" });
    setPageNumber(1);
    await fetchSchedules();
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const truncate = (str, len) => {
    if (str.length > len) {
      return str.substring(0, len) + '...';
    }
    return str;
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const checkMediaUrl = (schedule) => {
    return schedule.mediaUrl ? <Check style={{ color: "green" }} /> : null;
  };

  const renderTable = (schedules) => (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="center">Id</TableCell>
          <TableCell align="center">{i18n.t("schedules.table.contact")}</TableCell>
          <TableCell align="center">{i18n.t("schedules.table.body")}</TableCell>
          <TableCell align="center">{i18n.t("schedules.table.sendAt")}</TableCell>
          <TableCell align="center">Conexão</TableCell>
          <TableCell align="center">{i18n.t("schedules.table.status")}</TableCell>
          {tabValue === 0 &&
            <TableCell align="center">{i18n.t("schedules.table.actions")}</TableCell>}
          <TableCell align="center">Anexo</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <>
          {schedules.map((schedule) => (
            <TableRow key={schedule.id}>
              <TableCell align="center">{schedule.id}</TableCell>
              <TableCell align="center">{schedule.contact.name}</TableCell>
              <TableCell align="center" title={schedule.body}>{truncate(schedule.body, 25)}</TableCell>
              <TableCell align="center">{moment(schedule.sendAt).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
              {console.log(schedule)}
              <TableCell align="center">{schedule.whatsapp.name}</TableCell>

              <TableCell align="center">{capitalize(schedule.status)}</TableCell>
              {tabValue === 0 &&
                <TableCell align="center">
                  <IconButton size="small" onClick={() => handleEditSchedule(schedule)}>
                    <EditIcon />
                  </IconButton>

                  <IconButton size="small" onClick={() => {
                    setConfirmModalOpen(true);
                    setDeletingSchedule(schedule);
                  }}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </TableCell>
              }
              <TableCell align="center">
                {checkMediaUrl(schedule)}
              </TableCell>
            </TableRow>
          ))}
          {loading && <TableRowSkeleton columns={5} />}
        </>
      </TableBody>
    </Table>
  );

  useEffect(() => {
    setPendingSchedules(schedules.filter(schedule => schedule.status !== "ENVIADA"));
    setSentSchedules(schedules.filter(schedule => schedule.status === "ENVIADA"));    
  }, [schedules]);

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingSchedule &&
          `${i18n.t("schedules.confirmationModal.deleteTitle")}`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteSchedule(deletingSchedule.id)}
      >
        {i18n.t("schedules.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <ScheduleModal
        open={scheduleModalOpen}
        onClose={handleCloseScheduleModal}
        reload={fetchSchedules}
        aria-labelledby="form-dialog-title"
        scheduleId={selectedSchedule && selectedSchedule.id}
        contactId={contactId}
        cleanContact={cleanContact}
        setContactId={setContactId}
      />

      <ScheduleModalEdit
        open={editScheduleModalOpen}
        onClose={handleCloseEditScheduleModal}
        reload={fetchSchedules}
        scheduleId={selectedSchedule && selectedSchedule.id}
        contactId={contactId}
        setContactId={setContactId}
      />

      <MainHeader>
        <Title>{i18n.t("schedules.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenScheduleModal}
          >
            {i18n.t("schedules.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>

      <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
        <Tab label="Mensagens agendadas" style={{ fontWeight: 700 }} />
        <Tab label="Mensagens enviadas" style={{ fontWeight: 700 }} />
      </Tabs>

      <Paper className={classes.mainPaper} variant="outlined" onScroll={handleScroll}>
        <Box hidden={tabValue !== 0}>{renderTable(pendingSchedules)}</Box>
        <Box hidden={tabValue !== 1}>{renderTable(sentSchedules)}</Box>
      </Paper>

    </MainContainer>
  );
};

export default Schedules;
