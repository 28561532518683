import React, {useState, useEffect, useContext} from "react";

import * as Yup from "yup";
import {Formik, Form, Field} from "formik";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

import {makeStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import {i18n} from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import {FormControl} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment"
import {AuthContext} from "../../context/Auth/AuthContext";
import {isArray, capitalize} from "lodash";

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const ScheduleSchema = Yup.object().shape({
    body: Yup.string()
        .min(5, "Mensagem muito curta")
        .required("Obrigatório"),
    contactId: Yup.number().required("Obrigatório"),
    sendAt: Yup.string().required("Obrigatório")
});

const ScheduleModalEdit = ({open, onClose, scheduleId, contactId, cleanContact, reload, setContactId}) => {
    const classes = useStyles();
    const history = useHistory();
    const {user} = useContext(AuthContext);

    const initialState = {
        body: "",
        contactId: "",
        sendAt: moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm'),
        sentAt: "",
        mediaUrl: ""
    };

    const initialContact = {
        id: "",
        name: ""
    }

    const [schedule, setSchedule] = useState(initialState);
    const [currentContact, setCurrentContact] = useState(initialContact);
    const [contacts, setContacts] = useState([initialContact]);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        if (contactId && contacts.length) {//objetivo é setar o contato selecionado no modal
            const contact = contacts.find(c => c.id === contactId);
            if (contact) {
                setCurrentContact(contact);
            }
        }
    }, [contactId, contacts]);

    useEffect(() => {
        try {
            (async () => {
                const {data: contactList} = await api.get('/contacts/list');
                let customList = contactList.map((c) => ({id: c.id, name: c.name}));
                if (isArray(customList)) {//se for um array, adiciona o contato vazio
                    setContacts([{id: "", name: ""}, ...customList]);//adiciona o contato vazio
                }
                if (contactId) {//se tiver contato selecionado, seta o contato
                    setSchedule(prevState => {//seta o contato no schedule
                        return {...prevState, contactId}//seta o contato no schedule
                    });
                }

                if (!scheduleId) return;

                const {data} = await api.get(`/schedules/${scheduleId}`);
                setSchedule(prevState => {
                    return {...prevState, ...data, sendAt: moment(data.sendAt).format('YYYY-MM-DDTHH:mm')};
                });
                setCurrentContact(data.contact); //seta o contato no modal
            })()
        } catch (err) {
            toastError(err);
        }
    }, [scheduleId, contactId, open]);

    const handleFileChange = (event) => {setSelectedFile(event.target.files[0]);};

    const handleClose = () => {
        onClose();
        setSchedule(initialState);
    };

    const handleSaveSchedule = async values => {
        const scheduleData = {...values, userId: user.id};
        let mediaUrl = "";
        try {
            if (scheduleId) {
                await api.put(`/schedules/${scheduleId}`, scheduleData);
            } else {
                if (selectedFile) {
                    const formData = new FormData();
                    formData.append("file", selectedFile);
                    const {data: file} = await api.post("/files", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                    const dataf = file['fullname'];
                    console.log('dataf', dataf);
                    mediaUrl = dataf;
                } else {
                    mediaUrl = "";
                }
                scheduleData.mediaUrl = mediaUrl;
                await api.post("/schedules", scheduleData);
                console.log('scheduleData', scheduleData);
                
            }

            toast.success(i18n.t("scheduleModal.success"));
            if (typeof reload == 'function') {
                reload();
            }
            setCurrentContact(initialContact);
            setSchedule(initialState);
            history.push('/schedules')

        } catch (err) {
            toastError(err);
        }
        handleClose();
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    Editar agendamento
                </DialogTitle>
                <Formik
                    initialValues={schedule}
                    enableReinitialize={true}
                    validationSchema={ScheduleSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveSchedule(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({touched, errors, isSubmitting, values}) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.multFieldLine}>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                    >
                                        <Autocomplete
                                            fullWidth
                                            value={currentContact}
                                            options={contacts}
                                            onChange={(e, contact) => { //seta o contato no modal
                                                const contactId = contact ? contact.id : '';//seleciona o contato
                                                setSchedule({...schedule, contactId});//seta o contato no schedule
                                                setCurrentContact(contact ? contact : initialContact);
                                            }}
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option, value) => {
                                                return value.id === option.id
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined"
                                                                                placeholder="Contato"/>}
                                        />
                                    </FormControl>
                                </div>
                                <br/>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        rows={9}
                                        multiline={true}
                                        label={i18n.t("scheduleModal.form.body")}
                                        name="body"
                                        error={touched.body && Boolean(errors.body)}
                                        helperText={touched.body && errors.body}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                <br/>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("scheduleModal.form.sendAt")}
                                        type="datetime-local"
                                        name="sendAt"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={touched.sendAt && Boolean(errors.sendAt)}
                                        helperText={touched.sendAt && errors.sendAt}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <label htmlFor="contained-button-file">
                                    <Button variant="contained" component="span" startIcon={<CloudUploadIcon/>}>
                                        {selectedFile ? selectedFile.name : "ANEXAR ARQUIVO"}
                                    </Button>
                                </label>
                                <div style={{display: selectedFile ? "flex" : "none", alignItems: "center"}}>
                                    <Tooltip title="Remover arquivo" placement="top">
                                        <IconButton onClick={() => setSelectedFile(null)} color="error">
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <input
                                    accept=".pdf,.jpg,.jpeg,.png"
                                    style={{display: "none"}}
                                    id="contained-button-file"
                                    type="file"
                                    name={selectedFile}
                                    multiple={false}
                                    onChange={handleFileChange}
                                />
                            </DialogActions>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("scheduleModal.buttons.cancel")}
                                </Button>
                                {(schedule.sentAt === null || schedule.sentAt === "") && (
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmitting}
                                        variant="contained"
                                        className={classes.btnWrapper}
                                    >
                                        {scheduleId
                                            ? `${i18n.t("scheduleModal.buttons.okEdit")}`
                                            : `${i18n.t("scheduleModal.buttons.okAdd")}`}
                                        {isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </Button>
                                )}
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default ScheduleModalEdit;
